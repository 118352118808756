/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DashboardState, dashboardFeatureKey } from './dashboard.reducer';

export const selectDashboardFeature = createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectSiteList = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteList);
export const selectSiteListIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteListIsLoading);
export const selectSiteListError = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteListError);

export const selectSelectedSiteId = createSelector(selectDashboardFeature, (state: DashboardState) => state.selectedSiteId);

export const selectSiteStructure = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteStructure);
export const selectSiteStructureIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteStructureIsLoading);
export const selectSiteStructureError = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteStructureError);

export const selectSiteOverview = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteOverview);
export const selectSiteMetering = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteMetering);
export const selectSiteDataIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteDataIsLoading);
export const selectSiteDataError = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteDataError);

export const selectRefreshSiteDataIsLoading = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.refreshSiteDataIsLoading
);
export const selectRefreshSiteDataError = createSelector(selectDashboardFeature, (state: DashboardState) => state.refreshSiteDataError);

export const selectSelectedAssetId = createSelector(selectDashboardFeature, (state: DashboardState) => state.selectedAssetId);

export const selectAssetHistory = createSelector(selectDashboardFeature, (state: DashboardState) => state.assetHistory);
export const selectAssetHistoryIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.assetHistoryIsLoading);
export const selectAssetHistoryError = createSelector(selectDashboardFeature, (state: DashboardState) => state.assetHistoryError);

export const selectRefreshAssetHistoryIsLoading = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.refreshAssetHistoryIsLoading
);
export const selectRefreshAssetHistoryError = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.refreshAssetHistoryError
);
