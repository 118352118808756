/* eslint-disable unicorn/consistent-function-scoping */

/* eslint-disable @typescript-eslint/no-magic-numbers */
import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, input } from '@angular/core';

import { getRuntimeUniqueId } from '@shared/utils/runtime-unique-id';

@Component({
  selector: 'app-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrl: './loading-skeleton.component.scss',
  standalone: true,
  imports: [NgStyle, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSkeletonComponent implements OnInit {
  private readonly fixedId = getRuntimeUniqueId();

  public readonly idClip = `${this.fixedId}-diff`;
  public readonly idGradient = `${this.fixedId}-animated-diff`;
  public readonly idAria = `${this.fixedId}-aria`;

  public animate = input<boolean>(true);
  public baseUrl = input<string>('');
  public speed = input<number>(1.2);
  public viewBox = input<string>('0 0 400 130');
  public gradientRatio = input<number>(2);
  public backgroundTextClass = input<string>('text-background-container-secondary');
  public backgroundOpacity = input<number>(1);
  public foregroundTextClass = input<string>('text-background-panel');
  public foregroundOpacity = input<number>(1);
  public rtl = input<boolean>(false);
  public interval = input<number>(0.25);
  public style = input<object>({});

  public animationValues: string[] = [];

  public extendedStyle = computed(() => {
    const styleValue = this.style();
    const rtlValue = this.rtl();

    return rtlValue ? { ...styleValue, transform: 'scaleX(-1)' } : styleValue;
  });
  public clipPath!: string;
  public fillStyle!: object;
  public duration!: string;
  public keyTimes!: string;

  public ngOnInit(): void {
    this.clipPath = `url(${this.baseUrl()}#${this.idClip})`;
    this.fillStyle = { fill: `url(${this.baseUrl()}#${this.idGradient})` };

    this.duration = `${this.speed()}s`;
    this.keyTimes = `0; ${this.interval()}; 1`;
    this.animationValues = [
      `${-this.gradientRatio()}; ${-this.gradientRatio()}; 1`,
      `${-this.gradientRatio() / 2}; ${-this.gradientRatio() / 2}; ${1 + this.gradientRatio() / 2}`,
      `0; 0; ${1 + this.gradientRatio()}`,
    ];
  }
}
