export enum RouteSegment {
  Root = '',
  Sites = 'sites',
  Overview = 'overview',
  Vehicles = 'vehicles',
  Chargers = 'chargers',
  Analytics = 'analytics',
  Infrastructure = 'infrastructure',
  Alerting = 'alerting',
  AiInsights = 'ai-insights',
}
