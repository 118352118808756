import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SvgIconComponent, injectRegisterIcons } from '@ngneat/svg-icon';

import { appArrowLink32Icon } from '@icons/arrow-link-32';

@Component({
  selector: 'app-panel-link',
  standalone: true,
  imports: [SvgIconComponent, NgClass],
  templateUrl: './panel-link.component.html',
  styleUrl: './panel-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelLinkComponent {
  public label = input.required<string>();
  public iconClasses = input<string>();

  constructor() {
    injectRegisterIcons(appArrowLink32Icon);
  }
}
