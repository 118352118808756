<div
  class="flex flex-col transition-all"
  [ngClass]="{
    'w-[256px]': vm().expanded,
    'w-[88px]': !vm().expanded,
  }"
>
  <div class="flex items-center h-[100px] overflow-hidden">
    <div
      class="grow flex justify-center shrink-0"
      [ngClass]="{
        'w-[256px]': vm().expanded,
        'w-[88px]': !vm().expanded,
      }"
    >
      <img
        class="shrink-0 max-w-none"
        [ngClass]="{
          hidden: !vm().expanded,
        }"
        [width]="vm().expandedLogoWidth"
        [height]="vm().expandedLogoHeight"
        [src]="vm().expandedLogoSrc"
        [style.width.px]="vm().expandedLogoWidth"
        [style.height.px]="vm().expandedLogoHeight"
        alt="logo"
      />

      <img
        class="shrink-0 max-w-none"
        [ngClass]="{
          hidden: vm().expanded,
        }"
        [width]="vm().collapsedLogoWidth"
        [height]="vm().collapsedLogoHeight"
        [src]="vm().collapsedLogoSrc"
        [style.width.px]="vm().collapsedLogoWidth"
        [style.height.px]="vm().collapsedLogoHeight"
        alt="logo"
      />
    </div>
  </div>
  <div class="flex flex-col px-2">
    @for (route of routes; track route.labelTranslationKey) {
      <a
        [routerLink]="route.route"
        routerLinkActive="rla"
        [routerLinkActiveOptions]="{
          exact: !!route.exact,
        }"
        class="overflow-hidden rounded-radius-secondary"
      >
        <div class="menu-item-container flex items-center min-h-[72px] w-[240px]">
          <div class="flex pl-6 pr-4">
            <svg-icon class="transition-colors" [key]="route.icon" size="xl"></svg-icon>
          </div>
          <div
            class="grow text-xl font-medium transition-all"
            [ngClass]="{
              'translate-x-[0px]': vm().expanded,
              'translate-x-[10px]': !vm().expanded,
            }"
          >
            {{ route.labelTranslationKey | translate }}
          </div>
          <div class="p-2 self-stretch flex">
            <div class="active-route-indicator w-2 shrink-0 rounded-radius-tertiary transition-colors"></div>
          </div>
        </div>
      </a>
    }
  </div>
  <div class="self-end mt-6">
    <button
      type="button"
      (click)="onExpandToggle()"
      class="transition-all text-foreground-primary-60 hover:text-foreground-primary-100"
      [ngClass]="{
        'translate-x-[0px]': vm().expanded,
        'translate-x-[-24px]': !vm().expanded,
      }"
    >
      <svg-icon [key]="vm().expanded ? 'chevron-left-40' : 'chevron-right-40'" size="xxxl"></svg-icon>
    </button>
  </div>
</div>
