<svg class="grow" [attr.viewBox]="viewBox()" [ngStyle]="extendedStyle()">
  <rect role="presentation" [attr.clip-path]="clipPath" [ngStyle]="fillStyle" x="0" y="0" width="100%" height="100%" />

  <defs role="presentation">
    <clipPath [attr.id]="idClip">
      <ng-content></ng-content>
    </clipPath>

    <linearGradient [attr.id]="idGradient">
      <stop offset="0%" stop-color="currentColor" [ngClass]="backgroundTextClass()" [attr.stop-opacity]="backgroundOpacity()">
        @if (animate()) {
          <animate
            [attr.keyTimes]="keyTimes"
            attributeName="offset"
            [attr.values]="animationValues[0]"
            [attr.dur]="duration"
            repeatCount="indefinite"
          />
        }
      </stop>

      <stop offset="50%" stop-color="currentColor" [ngClass]="foregroundTextClass()" [attr.stop-opacity]="foregroundOpacity()">
        @if (animate()) {
          <animate
            attributeName="offset"
            [attr.keyTimes]="keyTimes"
            [attr.values]="animationValues[1]"
            [attr.dur]="duration"
            repeatCount="indefinite"
          />
        }
      </stop>

      <stop offset="100%" stop-color="currentColor" [ngClass]="backgroundTextClass()" [attr.stop-opacity]="backgroundOpacity()">
        @if (animate()) {
          <animate
            attributeName="offset"
            [attr.keyTimes]="keyTimes"
            [attr.values]="animationValues[2]"
            [attr.dur]="duration"
            repeatCount="indefinite"
          />
        }
      </stop>
    </linearGradient>
  </defs>
</svg>
