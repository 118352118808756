/* eslint-disable unicorn/consistent-function-scoping */
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap } from 'rxjs/operators';

import { DashboardService } from '@core/api/dashboard.service';

import { cancelOnAction } from '@shared/operators/cancel-on-action.operator';

import {
  clearDashboardData,
  loadAssetHistory,
  loadAssetHistoryFailure,
  loadAssetHistorySuccess,
  loadSiteData,
  loadSiteDataFailure,
  loadSiteDataSuccess,
  loadSiteList,
  loadSiteListFailure,
  loadSiteListSuccess,
  loadSiteStructure,
  loadSiteStructureFailure,
  loadSiteStructureSuccess,
  refreshAssetHistory,
  refreshAssetHistoryFailure,
  refreshAssetHistorySuccess,
  refreshSiteData,
  refreshSiteDataFailure,
  refreshSiteDataSuccess,
  selectAsset,
  selectSite,
} from './dashboard.actions';
import { selectSelectedAssetId, selectSelectedSiteId } from './dashboard.selectors';
import {
  mapAssetMeteringResponseApiModelToStoreModel,
  mapSiteMeteringResponseApiModelToStoreModel,
  mapSiteOverviewResponseApiModelToStoreModels,
  mapSiteStructureResponseApiModelToStoreModel,
  mapSitesResponseApiModelToSiteStoreModels,
} from './mappings';

@Injectable()
export class DashboardEffects {
  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store);
  readonly #dashboardService = inject(DashboardService);
  private readonly REFRESH_INTERVAL = 5000;

  public loadSiteList$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(loadSiteList),
      switchMap(() =>
        this.#dashboardService.getSiteList$().pipe(
          map((sitesResponse) => loadSiteListSuccess({ sites: mapSitesResponseApiModelToSiteStoreModels(sitesResponse) })),
          catchError(() =>
            of(
              loadSiteListFailure({
                error: 'Failed to load sites',
              })
            )
          )
        )
      ),
      cancelOnAction(this.#actions$, clearDashboardData)
    )
  );

  public loadSiteStructureOnSiteSelect$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(selectSite),
      map(({ siteId }) =>
        loadSiteStructure({
          siteId,
        })
      )
    )
  );

  public loadSiteStructure$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(loadSiteStructure),
      switchMap(({ siteId }) =>
        this.#dashboardService.getSiteStructure$(siteId).pipe(
          map((siteStructure) => loadSiteStructureSuccess({ siteStructure: mapSiteStructureResponseApiModelToStoreModel(siteStructure) })),
          catchError(() =>
            of(
              loadSiteStructureFailure({
                error: 'Failed to load site structure',
              })
            )
          )
        )
      ),
      cancelOnAction(this.#actions$, clearDashboardData)
    )
  );

  public loadSiteDataOnSiteSelect$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(selectSite),
      map(({ siteId }) =>
        loadSiteData({
          siteId,
        })
      )
    )
  );

  public loadSiteData$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(loadSiteData),
      switchMap(({ siteId }) =>
        forkJoin([this.#dashboardService.getSiteOverview$(siteId), this.#dashboardService.getSiteMetering$(siteId)]).pipe(
          map(([siteOverview, siteMetering]) =>
            loadSiteDataSuccess({
              siteOverview: mapSiteOverviewResponseApiModelToStoreModels(siteOverview),
              siteMetering: mapSiteMeteringResponseApiModelToStoreModel(siteMetering),
            })
          ),
          catchError(() =>
            of(
              loadSiteDataFailure({
                error: 'Failed to load site data',
              })
            )
          )
        )
      ),
      cancelOnAction(this.#actions$, clearDashboardData)
    )
  );

  public periodicRefreshSiteData$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(loadSiteDataSuccess, refreshSiteDataSuccess),
      delay(this.REFRESH_INTERVAL),
      map(() => refreshSiteData()),
      cancelOnAction(this.#actions$, [selectSite, clearDashboardData])
    );
  });

  public refreshSiteData$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(refreshSiteData),
      concatLatestFrom(() => this.#store.select(selectSelectedSiteId)),
      map(([, siteId]) => siteId),
      filter((siteId): siteId is string => !!siteId),
      switchMap((siteId) => {
        return forkJoin([this.#dashboardService.getSiteOverview$(siteId), this.#dashboardService.getSiteMetering$(siteId)]).pipe(
          map(([siteOverview, siteMetering]) =>
            refreshSiteDataSuccess({
              siteOverview: mapSiteOverviewResponseApiModelToStoreModels(siteOverview),
              siteMetering: mapSiteMeteringResponseApiModelToStoreModel(siteMetering),
            })
          ),
          catchError(() =>
            of(
              refreshSiteDataFailure({
                error: 'Failed to load site data',
              })
            )
          )
        );
      }),
      cancelOnAction(this.#actions$, [selectSite, clearDashboardData])
    );
  });

  public loadAssetDataOnAssetSelect$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(selectAsset),
      map(({ assetId }) =>
        loadAssetHistory({
          assetId,
        })
      )
    )
  );

  public loadAssetHistory$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(loadAssetHistory),
      switchMap(({ assetId }) =>
        this.#dashboardService.getAssetMetering$(assetId).pipe(
          map((assetMetering) =>
            loadAssetHistorySuccess({ assetMetering: mapAssetMeteringResponseApiModelToStoreModel(assetMetering, assetId) })
          ),
          catchError(() =>
            of(
              loadAssetHistoryFailure({
                error: 'Failed to load asset data',
              })
            )
          )
        )
      ),
      cancelOnAction(this.#actions$, [selectSite, selectAsset, clearDashboardData])
    )
  );

  public periodicRefreshAssetHistory$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(loadAssetHistorySuccess, refreshAssetHistorySuccess),
      delay(this.REFRESH_INTERVAL),
      map(() => refreshAssetHistory()),
      cancelOnAction(this.#actions$, [selectSite, selectAsset, clearDashboardData])
    );
  });

  public refreshAssetHistory$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(refreshAssetHistory),
      concatLatestFrom(() => this.#store.select(selectSelectedAssetId)),
      map(([, assetId]) => assetId),
      filter((assetId): assetId is string => !!assetId),
      switchMap((assetId) => {
        return this.#dashboardService.getAssetMetering$(assetId).pipe(
          map((assetMetering) =>
            refreshAssetHistorySuccess({ assetMetering: mapAssetMeteringResponseApiModelToStoreModel(assetMetering, assetId) })
          ),
          catchError(() =>
            of(
              refreshAssetHistoryFailure({
                error: 'Failed to load site data',
              })
            )
          )
        );
      }),
      cancelOnAction(this.#actions$, [selectSite, selectAsset, clearDashboardData])
    );
  });
}
