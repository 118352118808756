import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconComponent, SvgIcons, injectRegisterIcons } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

import { RouteSegment } from '@core/enums/route-segment.enum';

import { appAiInsights24Icon } from '@icons/ai-insights-24';
import { appChevronLeft40Icon } from '@icons/chevron-left-40';
import { appChevronRight40Icon } from '@icons/chevron-right-40';
import { appDashboard24Icon } from '@icons/dashboard-24';
import { appIssues24Icon } from '@icons/issues-24';
import { appReports24Icon } from '@icons/reports-24';
import { appSettings24Icon } from '@icons/settings-24';
import { appSites24Icon } from '@icons/sites-24';
import { appUsers24Icon } from '@icons/users-24';

import { SideMenuViewModel } from './side-menu.view-model';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [NgClass, TranslateModule, SvgIconComponent, RouterLink, RouterLinkActive],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent {
  public vm = input.required<SideMenuViewModel>();
  public expandChange = output<boolean>();

  public readonly routes: {
    icon: SvgIcons;
    labelTranslationKey: string;
    route: RouteSegment[] | null;
    exact?: boolean;
  }[] = [
    {
      icon: 'dashboard-24',
      labelTranslationKey: 'side-menu.dashboard',
      route: [RouteSegment.Root],
      exact: true,
    },
    {
      icon: 'sites-24',
      labelTranslationKey: 'side-menu.sites',
      route: [RouteSegment.Root, RouteSegment.Sites],
    },
    {
      icon: 'issues-24',
      labelTranslationKey: 'side-menu.issues',
      route: null,
    },
    {
      icon: 'ai-insights-24',
      labelTranslationKey: 'side-menu.ai-insights',
      route: null,
    },
    {
      icon: 'reports-24',
      labelTranslationKey: 'side-menu.reports',
      route: null,
    },
    {
      icon: 'users-24',
      labelTranslationKey: 'side-menu.users',
      route: null,
    },
    {
      icon: 'settings-24',
      labelTranslationKey: 'side-menu.settings',
      route: null,
    },
  ];

  constructor() {
    injectRegisterIcons([
      appDashboard24Icon,
      appSites24Icon,
      appIssues24Icon,
      appAiInsights24Icon,
      appReports24Icon,
      appUsers24Icon,
      appSettings24Icon,
      appChevronLeft40Icon,
      appChevronRight40Icon,
    ]);
  }

  public onExpandToggle(): void {
    this.expandChange.emit(!this.vm().expanded);
  }
}
