import { createReducer, on } from '@ngrx/store';

import {
  AssetMeteringStoreModel,
  SiteMeteringStoreModel,
  SiteOverviewStoreModel,
  SiteStoreModel,
  SiteStructureStoreModel,
} from '@domain/dashboard/dashboard.models';

import {
  clearDashboardData,
  loadAssetHistory,
  loadAssetHistoryFailure,
  loadAssetHistorySuccess,
  loadSiteData,
  loadSiteDataFailure,
  loadSiteDataSuccess,
  loadSiteList,
  loadSiteListFailure,
  loadSiteListSuccess,
  loadSiteStructure,
  loadSiteStructureFailure,
  loadSiteStructureSuccess,
  refreshAssetHistory,
  refreshAssetHistoryFailure,
  refreshAssetHistorySuccess,
  refreshSiteData,
  refreshSiteDataFailure,
  refreshSiteDataSuccess,
  selectAsset,
  selectSite,
} from './dashboard.actions';

export interface DashboardState {
  siteListIsLoading?: boolean;
  siteListError?: string;
  siteList?: SiteStoreModel[];

  selectedSiteId?: string;

  siteStructureIsLoading?: boolean;
  siteStructureError?: string;
  siteStructure?: SiteStructureStoreModel;

  siteDataIsLoading?: boolean;
  siteDataError?: string;
  siteOverview?: SiteOverviewStoreModel[];
  siteMetering?: SiteMeteringStoreModel;

  refreshSiteDataIsLoading?: boolean;
  refreshSiteDataError?: string;

  selectedAssetId?: string;

  assetHistoryIsLoading?: boolean;
  assetHistoryError?: string;
  assetHistory?: AssetMeteringStoreModel;

  refreshAssetHistoryIsLoading?: boolean;
  refreshAssetHistoryError?: string;
}

export const initialState: DashboardState = {};

export const dashboardFeatureKey = 'dashboard';

export const dashboardReducer = createReducer(
  initialState,

  on(loadSiteList, (state) => ({ ...state, siteListIsLoading: true, siteListError: undefined })),
  on(loadSiteListSuccess, (state, { sites }) => ({ ...state, siteListIsLoading: false, siteList: sites })),
  on(loadSiteListFailure, (state, { error }) => ({ ...state, siteListIsLoading: false, siteListError: error })),

  on(selectSite, (state, { siteId }) => ({
    ...state,
    selectedSiteId: siteId,

    siteStructureIsLoading: undefined,
    siteStructureError: undefined,
    siteStructure: undefined,

    siteDataIsLoading: undefined,
    siteDataError: undefined,
    siteOverview: undefined,
    siteMetering: undefined,

    refreshSiteDataIsLoading: undefined,
    refreshSiteDataError: undefined,

    selectedAssetId: undefined,

    assetHistoryIsLoading: undefined,
    assetHistoryError: undefined,
    assetHistory: undefined,

    refreshAssetHistoryIsLoading: undefined,
    refreshAssetHistoryError: undefined,
  })),

  on(loadSiteStructure, (state) => ({
    ...state,

    siteStructureIsLoading: true,
    siteStructureError: undefined,
  })),
  on(loadSiteStructureSuccess, (state, { siteStructure }) => ({
    ...state,
    siteStructureIsLoading: false,
    siteStructure,
  })),
  on(loadSiteStructureFailure, (state, { error }) => ({ ...state, siteStructureIsLoading: false, siteStructureError: error })),

  on(loadSiteData, (state) => ({
    ...state,

    siteDataIsLoading: true,
    siteDataError: undefined,
  })),
  on(loadSiteDataSuccess, (state, { siteOverview, siteMetering }) => ({
    ...state,
    siteDataIsLoading: false,
    siteOverview,
    siteMetering,
  })),
  on(loadSiteDataFailure, (state, { error }) => ({ ...state, siteDataIsLoading: false, siteDataError: error })),

  on(refreshSiteData, (state) => ({ ...state, refreshSiteDataIsLoading: true, refreshSiteDataError: undefined })),
  on(refreshSiteDataSuccess, (state, { siteOverview, siteMetering }) => ({
    ...state,
    refreshSiteDataIsLoading: false,
    siteOverview,
    siteMetering,
  })),
  on(refreshSiteDataFailure, (state, { error }) => ({ ...state, refreshSiteDataIsLoading: false, refreshSiteDataError: error })),

  on(selectAsset, (state, { assetId }) => ({
    ...state,
    selectedAssetId: assetId,

    assetHistoryIsLoading: undefined,
    assetHistoryError: undefined,
    assetHistory: undefined,

    refreshAssetHistoryIsLoading: undefined,
    refreshAssetHistoryError: undefined,
  })),

  on(loadAssetHistory, (state) => ({
    ...state,
    assetHistoryIsLoading: true,
    assetHistoryError: undefined,
  })),
  on(loadAssetHistorySuccess, (state, { assetMetering }) => ({
    ...state,
    assetHistoryIsLoading: false,
    assetHistory: assetMetering,
  })),
  on(loadAssetHistoryFailure, (state, { error }) => ({ ...state, assetHistoryIsLoading: false, assetHistoryError: error })),

  on(refreshAssetHistory, (state) => ({ ...state, refreshAssetHistoryIsLoading: true, refreshAssetHistoryError: undefined })),
  on(refreshAssetHistorySuccess, (state, { assetMetering }) => ({
    ...state,
    refreshAssetHistoryIsLoading: false,
    assetHistory: assetMetering,
  })),
  on(refreshAssetHistoryFailure, (state, { error }) => ({
    ...state,
    refreshAssetHistoryIsLoading: false,
    refreshAssetHistoryError: error,
  })),

  on(clearDashboardData, (state) => ({
    ...initialState,
    siteList: state.siteList,
  }))
);
