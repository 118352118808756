import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BreadcrumbItemViewModel } from '../../pages/layout/components/breadcrumbs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private readonly itemsSubject$ = new BehaviorSubject<(BreadcrumbItemViewModel | Observable<BreadcrumbItemViewModel>)[]>([]);
  public readonly items$ = this.itemsSubject$.asObservable();

  public addItem(item$: BreadcrumbItemViewModel | Observable<BreadcrumbItemViewModel>): void {
    this.itemsSubject$.next([...this.itemsSubject$.getValue(), item$]);
  }

  public removeItem(item$: BreadcrumbItemViewModel | Observable<BreadcrumbItemViewModel>): void {
    this.itemsSubject$.next(this.itemsSubject$.getValue().filter((i$) => i$ !== item$));
  }
}
