import { createAction, props } from '@ngrx/store';

import {
  AssetMeteringStoreModel,
  SiteMeteringStoreModel,
  SiteOverviewStoreModel,
  SiteStoreModel,
  SiteStructureStoreModel,
} from '@domain/dashboard/dashboard.models';

export const loadSiteList = createAction('[Dashboard] load site list');
export const loadSiteListSuccess = createAction('[Dashboard] load site list success', props<{ sites: SiteStoreModel[] }>());
export const loadSiteListFailure = createAction('[Dashboard] load site list failure', props<{ error: string }>());

export const selectSite = createAction('[Dashboard] select site', props<{ siteId: string }>());

export const loadSiteStructure = createAction('[Dashboard] load site structure', props<{ siteId: string }>());
export const loadSiteStructureSuccess = createAction(
  '[Dashboard] load site structure success',
  props<{ siteStructure: SiteStructureStoreModel }>()
);
export const loadSiteStructureFailure = createAction('[Dashboard] load site structure failure', props<{ error: string }>());

export const loadSiteData = createAction('[Dashboard] load site data', props<{ siteId: string }>());
export const loadSiteDataSuccess = createAction(
  '[Dashboard] load site data success',
  props<{ siteOverview: SiteOverviewStoreModel[]; siteMetering: SiteMeteringStoreModel }>()
);
export const loadSiteDataFailure = createAction('[Dashboard] load site data failure', props<{ error: string }>());

export const refreshSiteData = createAction('[Dashboard] refresh site data');
export const refreshSiteDataSuccess = createAction(
  '[Dashboard] refresh site data success',
  props<{ siteOverview: SiteOverviewStoreModel[]; siteMetering: SiteMeteringStoreModel }>()
);
export const refreshSiteDataFailure = createAction('[Dashboard] refresh site data failure', props<{ error: string }>());

export const selectAsset = createAction('[Dashboard] select asset', props<{ assetId: string }>());

export const loadAssetHistory = createAction('[Dashboard] load asset history', props<{ assetId: string }>());
export const cancelLoadAssetHistory = createAction('[Dashboard] cancel load asset history');
export const loadAssetHistorySuccess = createAction(
  '[Dashboard] load asset history success',
  props<{ assetMetering: AssetMeteringStoreModel }>()
);
export const loadAssetHistoryFailure = createAction('[Dashboard] load asset history failure', props<{ error: string }>());

export const refreshAssetHistory = createAction('[Dashboard] refresh asset history');
export const refreshAssetHistorySuccess = createAction(
  '[Dashboard] refresh asset history success',
  props<{ assetMetering: AssetMeteringStoreModel }>()
);
export const refreshAssetHistoryFailure = createAction('[Dashboard] refresh asset history failure', props<{ error: string }>());

export const clearDashboardData = createAction('[Dashboard] clear dashboard data');
