import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';

import { connectToWebsocketSuccess, websocketClosed, websocketFailure } from '@core/stores/websocket/websocket.actions';
import { CONFIG_TOKEN } from '@core/tokens';

import { WebsocketMessageBase } from './websocket.types';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  readonly #store = inject(Store);
  readonly #config = inject(CONFIG_TOKEN);

  private readonly topicObserversMap: Record<string, Subscriber<WebsocketMessageBase>[]> = {};

  private readonly socketSubject$ = webSocket({
    url: this.#config.backendWebsocketUrl,
    openObserver: {
      next: () => {
        this.#store.dispatch(connectToWebsocketSuccess());
      },
      error: (error) => {
        this.#store.dispatch(websocketFailure({ error: error as string }));
      },
    },
    closeObserver: {
      next: () => {
        this.#store.dispatch(websocketClosed());
      },
    },
  });
  private readonly messageSubject$ = new Subject<WebsocketMessageBase>();
  private socketSubscription?: Subscription;

  public connect(): void {
    this.socketSubscription?.unsubscribe();

    this.socketSubscription = this.socketSubject$.subscribe({
      next: (message) => {
        this.messageSubject$.next(message as WebsocketMessageBase);
      },
      error: (error) => {
        this.#store.dispatch(websocketFailure({ error: error as string }));
      },
      complete: () => {},
    });
  }

  public connectToTopic$<T extends WebsocketMessageBase>(topic: string): Observable<T> {
    return new Observable((observer) => {
      const sub = this.messageSubject$.subscribe({
        next: (message) => {
          if (message.topic === topic) {
            observer.next(message as T);
          }
        },
        error: (error) => {
          this.removeSubscriberFromTopic(topic, observer);
          observer.error(error);
        },
        complete: () => {
          this.removeSubscriberFromTopic(topic, observer);
          observer.complete();
        },
      });

      this.addNewSubscriberToTopic(topic, observer);

      return () => {
        sub.unsubscribe();
        this.removeSubscriberFromTopic(topic, observer);
      };
    });
  }

  private addNewSubscriberToTopic(topic: string, Subscriber: Subscriber<WebsocketMessageBase>): void {
    if (!this.topicObserversMap[topic]) {
      this.topicObserversMap[topic] = [];
    }

    this.topicObserversMap[topic].push(Subscriber);

    if (this.topicObserversMap[topic].length === 1) {
      this.socketSubject$.next({ type: 'subscribe', topic });
    }
  }

  private removeSubscriberFromTopic(topic: string, subscriber: Subscriber<WebsocketMessageBase>): void {
    if (!this.topicObserversMap[topic]) {
      return;
    }

    this.topicObserversMap[topic] = this.topicObserversMap[topic].filter((sub) => sub !== subscriber);

    if (this.topicObserversMap[topic].length === 0) {
      delete this.topicObserversMap[topic];
      this.socketSubject$.next({ type: 'unsubscribe', topic });
    }
  }
}
